import { FilledInputClasses } from '@mui/material/FilledInput/filledInputClasses';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { palette } from '../awsPalette';

export const muiFilledInput: Partial<
  OverridesStyleRules<keyof FilledInputClasses, 'MuiFilledInput', unknown>
> = {
  root: {
    backgroundColor: palette.textField,
  },
  underline: {
    '&:before': {
      borderBottomColor: 'rgba(0,0,0,0)',
    },
    '&$disabled': {
      '&:before': {
        borderStyle: 'none',
      },
    },
    '&:hover': {
      '&:before': {
        borderBottomColor: palette.primary.main,
      },
    },
  },
};
