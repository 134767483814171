import { createTheme } from '@mui/material/styles';
import { palette } from './awsPalette';
import { awsTypography } from './awsTypography';
import { components } from './overrides/index';

const awsTheme = createTheme({
  palette,
  typography: awsTypography,
  components,
  height: {
    topBar: 100,
    topBarBorder: 5,
  },
  width: {
    drawer: 200,
    snackbar: 320,
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
  },
  popper: 1300,
});

export default awsTheme;
