import { PaperClasses } from '@mui/material/Paper/paperClasses';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { palette } from '../awsPalette';

export const muiPaper: Partial<OverridesStyleRules<keyof PaperClasses, 'MuiPaper', unknown>> = {
  root: {
    backgroundColor: palette.white,
  },
  elevation1: {
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.14)',
  },
};
